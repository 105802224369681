import { choose } from "./helpers"

function boysName() {
  return choose([...top50EnglishBoysNames, ...neutralNames, ...starwarsInspiredMaleNames])
}

function girlsName() {
  return choose([...top50EnglishGirlsNames, ...neutralNames, ...starwarsInspiredFemaleNames])
}

function neutralName() {
 return choose([...neutralNames, ...starwarsInspiredMaleNames])
}

function constellation() {
  return choose(constellations)
}

export {
  boysName, girlsName, neutralName, constellation
}

const top50EnglishBoysNames = [
  'Oliver',
  'Noah',
  'George',
  'Arthur',
  'Freddie',
  'Leo',
  'Theo',
  'Oscar',
  'Charlie',
  'Harry',
  'Archie',
  'Jack',
  'Henry',
  'Tommy',
  'Thomas',
  'Jacob',
  'Finley',
  'Lucas',
  'Isaac',
  'James',
  'Teddy',
  'Max',
  'William',
  'Joshua',
  'Harrison',
  'Mason',
  'Arlo',
  'Logan',
  'Theodore',
  'Elijah',
  'Roman',
  'Ethan',
  'Albie',
  'Rory',
  'Reggie',
  'Alexander',
  'Adam',
  'Jaxon',
  'Reuben',
  'Toby',
  'Hunter',
  'Luca',
  'Dylan',
  'Daniel',
  'Edward',
  'Frankie',
  'Louie',
  'Jude',
  'Hugo'
]

const top50EnglishGirlsNames = [
  'Olivia',
  'Amelia',
  'Isla',
  'Ava',
  'Mia',
  'Grace',
  'Freya',
  'Lily',
  'Sophia',
  'Ivy',
  'Emily',
  'Willow',
  'Evie',
  'Poppy',
  'Isabella',
  'Rosie',
  'Ella',
  'Elsie',
  'Florence',
  'Sienna',
  'Sophie',
  'Harper',
  'Evelyn',
  'Daisy',
  'Charlotte',
  'Millie',
  'Alice',
  'Phoebe',
  'Sofia',
  'Ruby',
  'Maisie',
  'Luna',
  'Isabelle',
  'Emilia',
  'Matilda',
  'Aria',
  'Maya',
  'Jessica',
  'Scarlett',
  'Layla',
  'Eva',
  'Arabella',
  'Ada',
  'Bonnie',
  'Ellie',
  'Hallie',
  'Violet',
  'Bella',
  'Esme',
  'Eliza'
]

const neutralNames = [
  'Addison',
  'Adrian',
  'Ainsley',
  'Alex',
  'Andy',
  'Ari',
  'Ash',
  'Aspen',
  'Aubrey',
  'Bailey',
  'Bay',
  'Blaine',
  'Bobbie',
  'Brett',
  'Brook',
  'Caelan',
  'Campbell',
  'Carroll',
  'Charlie',
  'Chris',
  'Clay',
  'Corey',
  'Dana',
  'Dakota',
  'Dale',
  'Daryl',
  'Devin',
  'Dorian',
  'Drew',
  'Eddie',
  'Eli',
  'Elliott',
  'Frances',
  'Frankie',
  'Gabriel',
  'Glenn',
  'Gray',
  'Harper',
  'Hayden',
  'Jamie',
  'Jean',
  'Jesse',
  'Jordan',
  'Jules',
  'Julian',
  'Kaden',
  'Kelly',
  'Kennedy',
  'Lake',
  'Logan',
  'Max',
  'Morgan',
  'Pat',
  'Peyton',
  'Ray',
  'Reed',
  'Riley',
  'River',
  'Roan',
  'Rudy',
  'Ryan',
  'Sage',
  'Sam',
  'Shawn',
  'Sean',
  'Stevie',
  'Tanner',
  'Taylor',
  'Toby',
  'Tyler',
  'Val',
  'West',
  'Winter',
]

const starwarsInspiredMaleNames = [
  'Nils',
  'Triko',
  'Karth',
  'Varon',
  'Ren',
  'Arciam',
  'Valek',
  'Daska',
  'Jaalib',
  'Ahri',
  'Cid',
  'Berek',
  'Olan',
  'Ekeb',
  'Bril',
  'Klai',
  'Rast',
  'Ban',
]

const starwarsInspiredFemaleNames = [
  'Jasmille',
  'Lakai',
  'Alcina',
  'Rena',
  'Munala',
  'Shandria',
  'Aerena',
  'Aria',
  'Nikana',
  'Caileta',
  'Jenica',
  'Arcina',
  'Akiva',
  'Eriana',
  'Dia',
  'Nuada',
  'Sarli',
  'Gisaria',
  'Senesha',
  'Heleya',
  'Risa',
  'Ria',
  'Ertia'
]

const constellations = [
  'Andromeda',
  'Antlia',
  'Apus',
  'Aquarius',
  'Aquila',
  'Ara',
  'Aries',
  'Auriga',
  'Boötes',
  'Caelum',
  'Camelopardalis',
  'Cancer',
  'Canes-Venatici',
  'Canis-Major',
  'Canis-Minor',
  'Capricornus',
  'Carina',
  'Cassiopeia',
  'Centaurus',
  'Cepheus',
  'Cetus',
  'Chamaeleon',
  'Circinus',
  'Columba',
  'Coma-Berenices',
  'Corona-Australis',
  'Corona-Borealis',
  'Corvus',
  'Crater',
  'Crux',
  'Cygnus',
  'Delphinus',
  'Dorado',
  'Draco',
  'Equuleus',
  'Eridanus',
  'Fornax',
  'Gemini',
  'Grus',
  'Hercules',
  'Horologium',
  'Hydra',
  'Hydrus',
  'Indus',
  'Lacerta',
  'Leo',
  'Leo-Minor',
  'Lepus',
  'Libra',
  'Lupus',
  'Lynx',
  'Lyra',
  'Mensa',
  'Microscopium',
  'Monoceros',
  'Musca',
  'Norma',
  'Octans',
  'Ophiuchus',
  'Orion',
  'Pegasus',
  'Perseus',
  'Phoenix',
  'Pictor',
  'Pisces',
  'Piscis-Austrinus',
  'Pleiades',
  'Praesepe',
  'Puppis',
  'Pyxis',
  'Reticulum',
  'Sagitta',
  'Sagittarius',
  'Scorpius',
  'Sculptor',
  'Scutum',
  'Serpens',
  'Sextans',
  'Taurus',
  'Telescopium',
  'Triangulum',
  'Triangulum-Australe',
  'Tucana',
  'Ursa Major',
  'Ursa Minor',
  'Vela',
  'Virgo',
  'Volans',
  'Vulpecula',
]